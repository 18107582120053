<template>
  <div>Logout</div>
</template>

<script>

export default {
  name: 'Logout',
  mounted () {
    this.$store.dispatch('security/logout').then(() => {
      this.$router.push({ path: '/dashboard' }).catch(() => {
        // Der Push liefert einen Error, weil /dashboard mittels Auth geschützt ist
        // der Router redirected aber automatisch auf das Login
      })
    })
      .catch(function (error) {
        console.log(error)
      })
  }
}
</script>
